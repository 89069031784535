import React, { Component } from 'react';
import { Table, Button } from 'reactstrap';

export class BackendBasicInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [
                { id: 1, name: 'John', age: 30 },
                { id: 2, name: 'Jane', age: 25 },
                { id: 3, name: 'Doe', age: 35 },
            ],
            editingId: null,
            newName: '',
            newAge: '',
            originalName: '',
            originalAge: '',
        };
    }

    handleEdit = (id, name, age) => {
        this.setState({
            editingId: id,
            newName: name,
            newAge: age,
            originalName: name,
            originalAge: age,
        });
    };

    handleSave = () => {
        const { data, editingId, newName, newAge } = this.state;
        const newData = data.map(item => {
            if (item.id === editingId) {
                return { ...item, name: newName, age: newAge };
            }
            return item;
        });
        this.setState({
            data: newData,
            editingId: null,
            newName: '',
            newAge: '',
        });
    };

    handleCancel = () => {
        const { data, editingId, originalName, originalAge } = this.state;
        const newData = data.map(item => {
            if (item.id === editingId) {
                return { ...item, name: originalName, age: originalAge };
            }
            return item;
        });
        this.setState({
            data: newData,
            editingId: null,
            newName: '',
            newAge: '',
        });
    };

    render() {
        const { data, editingId, newName, newAge } = this.state;
        return (
            <Table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Age</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map(item => (
                        <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{editingId === item.id ? <input type="text" value={newName} onChange={(e) => this.setState({ newName: e.target.value })} /> : item.name}</td>
                            <td>{editingId === item.id ? <input type="text" value={newAge} onChange={(e) => this.setState({ newAge: e.target.value })} /> : item.age}</td>
                            <td>
                                {editingId === item.id ?
                                    <>
                                        <Button color="success" onClick={() => this.handleSave()}>Save</Button>
                                        <Button color="danger" onClick={() => this.handleCancel()}>Cancel</Button>
                                    </> :
                                    <Button color="warning" onClick={() => this.handleEdit(item.id, item.name, item.age)}>Edit</Button>
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        );
    }
}
