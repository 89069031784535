import React, { Component } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout/Layout';
import { FrontLayout } from './components/Layout/FrontLayout';
import { BackendLayout } from './components/Layout/BackendLayout';
import './custom.css';

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Routes>
                    {/* Add this route to handle Swagger UI requests */}
                    <Route
                        path="/swagger/*"
                        element={<Navigate to="/swagger/index.html" replace />}
                    />

                    {AppRoutes.map((route, index) => {
                        const { element, ...rest } = route;
                        if (rest.path && rest.path.toLowerCase().startsWith('/backend')) {
                            return (
                                <Route key={index} {...rest} element={<BackendLayout>{element}</BackendLayout>} />
                            );
                        } else if (rest.path && rest.path.toLowerCase().startsWith('/swagger')) {
                            //return (
                            //    <Route key={index} {...rest} element={<BackendLayout>{element}</BackendLayout>} />
                            //);
                        } else {
                            return (
                                <Route key={index} {...rest} element={<FrontLayout>{element}</FrontLayout>} />
                            );
                        }
                    })}
                </Routes>
            </Layout>
        );
    }
}