import React, { useState, useEffect, useRef } from 'react';
import * as signalR from "@microsoft/signalr";
import './Style/ChatRoom.css';

const CHAT_HUB_URL = process.env.REACT_APP_CHAT_HUB_URL;

function ChatRoom({ user, onLogout }) {
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [onlineUsers, setOnlineUsers] = useState([]);
    const [connection, setConnection] = useState(null);
    const [connectionError, setConnectionError] = useState(false);
    const messagesEndRef = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        if (!user) {
            onLogout();
            return;
        }
        // 在組件掛載後將焦點設置在消息輸入框上
        inputRef.current?.focus();

        console.log('CHAT_HUB_URL:', CHAT_HUB_URL); // 輸出 
        console.log('Attempting to connect to SignalR hub...');
        const newConnection = new signalR.HubConnectionBuilder()
            .withUrl(`${CHAT_HUB_URL}?username=${encodeURIComponent(user)}`)
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);

        return () => {
            if (newConnection.state === signalR.HubConnectionState.Connected) {
                newConnection.stop();
            }
        };
    }, [user, onLogout]);

    useEffect(() => {
        if (connection) {
            connection.start()
                .then(() => {
                    console.log('SignalR Connected!');
                    setConnectionError(false);
                    connection.on('ReceiveMessage', (user, message) => {
                        setMessages(prevMessages => [...prevMessages, { user, message }]);
                    });
                    connection.on('UpdateUserList', (users) => {
                        console.log('Received updated user list:', users);
                        try {
                            setOnlineUsers(users);
                        } catch (error) {
                            console.error('Error updating online users:', error);
                        }
                    });
                    //connection.invoke('RequestUserList').catch(err => console.error('Error requesting user list:', err));
                })
                .catch(e => {
                    console.error('Connection failed: ', e);
                    setConnectionError(true);
                });
        }
    }, [connection]);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    const sendMessage = async (e) => {
        e.preventDefault();
        if (connection && connection.state === signalR.HubConnectionState.Connected && message.trim()) {
            try {
                await connection.send('SendMessage', user, message);
                setMessage('');
                // 在發送消息後將焦點重新設置在輸入框上
                inputRef.current?.focus();
            } catch (error) {
                console.error('Error sending message:', error);
                setConnectionError(true);
            }
        }
    };

    if (!user) {
        return null;
    }

    if (connectionError) {
        return (
            <div className="dos-chat">
                <div className="titlebar">Error</div>
                <div className="content">
                    <p className="error-message">There was an error connecting to the chat. Please try again later.</p>
                    <button className="button" onClick={onLogout}>Back to Login</button>
                </div>
            </div>
        );
    }

    return (
        <div className="dos-chat">
            <div className="sidebar">
                <div className="sidebar-content">
                    <button className="button" onClick={onLogout}>EXIT</button>
                    <div className="titlebar">Online Users</div>
                    <ul className="user-list">
                        {onlineUsers.length > 0 ? (
                            onlineUsers.map((onlineUser, index) => (
                                <li key={index}>{onlineUser}</li>
                            ))
                        ) : (
                            <li>No users online</li>
                        )}
                    </ul>
                </div>
            </div>
            <div className="main-chat">
                <div className="titlebar">DOS Chat - {user}</div>
                <div className="content">
                    {messages.map((m, index) => (
                        <div key={index} className="message">
                            <strong>{m.user}: </strong>{m.message}
                        </div>
                    ))}
                    <div ref={messagesEndRef} />
                </div>
                <form onSubmit={sendMessage} className="input-area">
                    <span className="prompt">{user}&gt;</span>
                    <div className="input-wrapper">
                        <span className="blinking-cursor">█</span>
                        <input
                            type="text"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            placeholder="Type a message..."
                            ref={inputRef}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ChatRoom;