// src/MingResult.js
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Style/chart.css';

function MingResult() {
    const location = useLocation();
    const navigate = useNavigate();
    const { birthdate, birthtime } = location.state || {};
    const [data, setData] = useState({});

    useEffect(() => {
        if (birthdate && birthtime) {
            fetch('CalculateBazi', {  // Replace with your API endpoint
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    birthDate: birthdate,
                    birthTime: birthtime
                })
            })
                .then(response => response.json())
                .then(data => {
                    setData(data);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }, [birthdate, birthtime]); // Only run when birthdate or birthtime changes

    const handleBack = () => {
        navigate(-1); // Go back to the previous page
    };
    //https://myfate.herokuapp.com/
    return (
        <div>
            <button onClick={handleBack}>返回</button>
            <div className="chart-container">



                <div class="flexcontainer">
      
                    <div class="subcontainer">
                        <div class="h">陽女、肖馬</div>
                    </div>
                    <div class="hcontainer">
                        <div class="h">西</div>
                        <div class="h">元</div>
                        <div class="h">{data.ceDate?.year}</div>
                        <div class="h">年</div>
                        <div class="h">{data.ceDate?.month}</div>
                        <div class="h">月</div>
                        <div class="h">{data.ceDate?.day}</div>
                        <div class="h">日</div>
                        <div class="h">{data.hour?.zhi}</div>
                        <div class="h">時</div>
                        <div class="h">生</div>
                    </div>
                    <div class="hcontainer">
                        <div class="h">農</div>
                        <div class="h">曆</div>
                        <div class="h">{data.lunarDate?.year}</div>
                        <div class="h">年</div>
                        <div class="h">{data.lunarDate?.month}</div>
                        <div class="h">月</div>
                        <div class="h">{data.lunarDate?.day}</div>
                        <div class="h">日</div>
                        <div class="h">{data.hour?.zhi}</div>
                        <div class="h">時</div>
                        <div class="h">生</div>
                    </div>
                    <div class="subcontainer">
                        <div class="s">
                            <span class="v">胎元</span>
                            <hr/>
                                <span class="v">乙巳</span>
                        </div>
                        <div class="s">
                            <span class="v">命宮</span>
                            <hr/>
                                <span class="v">壬戌</span>
                        </div>
                        <div class="s">
                            <span class="v">胎息</span>
                            <hr/>
                                <span class="v">癸卯</span>
                        </div>
                        <div class="s">
                            <span class="v">身宮</span>
                            <hr/>
                                <span class="v">庚申</span>
                        </div>
                        <div class="s">
                            <span class="v">年空</span>
                            <hr/>
                                <span class="v">子丑</span>
                        </div>
                        <div class="s">
                            <span class="v">日空</span>
                            <hr/>
                                <span class="v">辰巳</span>
                        </div>
                        <div class="s">
                            <span class="v">星座</span>
                            <hr/>
                                <span class="v">水瓶</span>
                        </div>
                    </div>
                    <div class="subcontainer">
                        <div class="t"><span class="v">主星</span></div>
                        <div class="s"><span class="v">{data.year?.ganGod}</span></div>
                        <div class="s"><span class="v">{data.month?.ganGod}</span></div>
                        <div class="s"><span class="v">日主</span></div>
                        <div class="s"><span class="v">{data.hour?.ganGod}</span></div>
                    </div>
                    <div class="subcontainer">
                        <div class="t"><span class="vb">八字</span></div>
                        <div class="pillar"><span class="vb">{data.year?.gan}{data.year?.zhi}</span></div>
                        <div class="pillar"><span class="vb">{data.month?.gan}{data.month?.zhi}</span></div>
                        <div class="pillar"><span class="vb">{data.day?.gan}{data.day?.zhi}</span></div>
                        <div class="pillar"><span class="vb">{data.hour?.gan}{data.hour?.zhi}</span></div>
                    </div>
                    <div class="subcontainer">
                        <div class="t"><span class="vs">藏</span></div>
                        <div class="s"><span class="vs">{data.year?.hiddenZhi[2]}</span><span class="vs">{data.year?.hiddenZhi[1]}</span><span class="vs">{data.year?.hiddenZhi[0]}</span></div>
                        <div class="s"><span class="vs">{data.month?.hiddenZhi[2]}</span><span class="vs">{data.month?.hiddenZhi[1]}</span><span class="vs">{data.month?.hiddenZhi[0]}</span></div>
                        <div class="s"><span class="vs">{data.day?.hiddenZhi[2]}</span><span class="vs">{data.day?.hiddenZhi[1]}</span><span class="vs">{data.day?.hiddenZhi[0]}</span></div>
                        <div class="s"><span class="vs">{data.hour?.hiddenZhi[2]}</span><span class="vs">{data.hour?.hiddenZhi[1]}</span><span class="vs">{data.hour?.hiddenZhi[0]}</span></div>
                    </div>
                    <div class="subcontainer">
                        <div class="t"><span class="v">副星</span></div>
                        <div class="s"><span class="v">　</span><span class="v">劫財</span><span class="v">正印</span></div>
                        <div class="s"><span class="v">比肩</span><span class="v">偏印</span><span class="v">七殺</span></div>
                        <div class="s"><span class="v">正印</span><span class="v">傷官</span><span class="v">比肩</span></div>
                        <div class="s"><span class="v">比肩</span><span class="v">食神</span><span class="v">偏印</span></div>
                    </div>
                    <div class="subcontainer">
                        <div class="t"><span class="v">大運</span></div>

                        <div class="s"><span>1</span><hr/><span class="v">癸丑</span></div>

                        <div class="s"><span>11</span><hr/><span class="v">壬子</span></div>

                        <div class="s"><span>21</span><hr/><span class="v">辛亥</span></div>

                        <div class="s"><span>31</span><hr/><span class="v">庚戌</span></div>

                        <div class="s"><span>41</span><hr/><span class="v">己酉</span></div>

                        <div class="s"><span>51</span><hr/><span class="v">戊申</span></div>

                        <div class="s"><span>61</span><hr/><span class="v">丁未</span></div>

                        <div class="s"><span>71</span><hr/><span class="v">丙午</span></div>

                        <div class="s"><span>81</span><hr/><span class="v">乙巳</span></div>

                        <div class="s"><span>91</span><hr/><span class="v">甲辰</span></div>

                    </div>
                    <div class="subcontainer">
                        <div class="h text-center">
                            出生後0年3個月又17天上大運
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MingResult;
