import React from 'react';
import styles from './../Style/Footer.module.css';

function Footer() {
    return (
        <footer className={styles.footer}>
            <p>&copy; {new Date().getFullYear()} 文章作者。保留所有權利。</p>
        </footer>
    );
}

export default Footer;
