import React from 'react';
import { Link } from 'react-router-dom';
import styles from './../Style/Intro.module.css';
import Footer from './Footer';

function SolarTerm() {
    return (
        <div className={styles.articleContainer}>
            <div className={styles.articleContentWrapper}>
                <header className={styles.articleHeader}>
                    <h1>二十四節氣介紹</h1>
                    <p>了解太陽在黃道上的位置與節氣的關係</p>
                </header>

                <section className={styles.articleContent}>
                    <p>
                        二十四節氣是基於太陽在黃道上的位置劃分的，每個節氣對應著太陽在黃道上的特定位置。因此，每個節氣的時間通常非常固定，但因為地球繞太陽運行的週期不是精確的365天，因此每年會有細微的變化。
                    </p>
                    <p>
                        以下是各節氣的基本邏輯，對應到西曆（陽曆）上的大致日期範圍：
                    </p>

                    <h3>春季</h3>
                    <ul>
                        <li><strong>立春</strong> - 2月3日至5日（太陽到達黃經315度）</li>
                        <li><strong>雨水</strong> - 2月18日至20日（太陽到達黃經330度）</li>
                        <li><strong>驚蟄</strong> - 3月5日至7日（太陽到達黃經345度）</li>
                        <li><strong>春分</strong> - 3月20日至22日（太陽到達黃經0度）</li>
                        <li><strong>清明</strong> - 4月4日至6日（太陽到達黃經15度）</li>
                        <li><strong>穀雨</strong> - 4月19日至21日（太陽到達黃經30度）</li>
                    </ul>

                    <h3>夏季</h3>
                    <ul>
                        <li><strong>立夏</strong> - 5月5日至7日（太陽到達黃經45度）</li>
                        <li><strong>小滿</strong> - 5月20日至22日（太陽到達黃經60度）</li>
                        <li><strong>芒種</strong> - 6月5日至7日（太陽到達黃經75度）</li>
                        <li><strong>夏至</strong> - 6月21日至22日（太陽到達黃經90度）</li>
                        <li><strong>小暑</strong> - 7月6日至8日（太陽到達黃經105度）</li>
                        <li><strong>大暑</strong> - 7月22日至24日（太陽到達黃經120度）</li>
                    </ul>

                    <h3>秋季</h3>
                    <ul>
                        <li><strong>立秋</strong> - 8月7日至9日（太陽到達黃經135度）</li>
                        <li><strong>處暑</strong> - 8月22日至24日（太陽到達黃經150度）</li>
                        <li><strong>白露</strong> - 9月7日至9日（太陽到達黃經165度）</li>
                        <li><strong>秋分</strong> - 9月22日至24日（太陽到達黃經180度）</li>
                        <li><strong>寒露</strong> - 10月7日至9日（太陽到達黃經195度）</li>
                        <li><strong>霜降</strong> - 10月23日至24日（太陽到達黃經210度）</li>
                    </ul>

                    <h3>冬季</h3>
                    <ul>
                        <li><strong>立冬</strong> - 11月7日至8日（太陽到達黃經225度）</li>
                        <li><strong>小雪</strong> - 11月22日至23日（太陽到達黃經240度）</li>
                        <li><strong>大雪</strong> - 12月6日至8日（太陽到達黃經255度）</li>
                        <li><strong>冬至</strong> - 12月21日至23日（太陽到達黃經270度）</li>
                        <li><strong>小寒</strong> - 1月5日至7日（太陽到達黃經285度）</li>
                        <li><strong>大寒</strong> - 1月20日至21日（太陽到達黃經300度）</li>
                    </ul>
                </section>
                <div className={styles.linkContainer}>
                    <Link to="/SolarTermSearch" className={styles.searchLink}>
                        查詢特定年份的節氣
                    </Link>
                </div>
                <Footer />
            </div>
        </div>
    );
}

export default SolarTerm;
