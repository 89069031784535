import React from 'react';
import './Style/ReservoirGrid.css';

const CircularWaterJug = ({ fillPercentage }) => {
    const waterHeight = 100 - fillPercentage;

    return (
        <div className="water-jug">
            <svg viewBox="0 0 100 110" className="jug-svg">
                <defs>
                    <clipPath id="jugClip">
                        <path d="M10,55 a40,45 0 1,0 80,0 a40,45 0 1,0 -80,0 z" />
                    </clipPath>
                </defs>

                {/* Jug body */}
                <path d="M10,55 a40,45 0 1,0 80,0 a40,45 0 1,0 -80,0 z"
                    fill="#E6F0FF" stroke="#3B82F6" strokeWidth="2" />

                {/* Jug opening */}
                <path d="M30,10 a20,5 0 0,1 40,0"
                    fill="none" stroke="#3B82F6" strokeWidth="2" />

                {/* Water */}
                <rect x="0" y={waterHeight} width="100" height="100" fill="#3B82F6" clipPath="url(#jugClip)">
                    <animate attributeName="y"
                        values={`${waterHeight};${waterHeight - 2};${waterHeight}`}
                        dur="2s" repeatCount="indefinite" />
                </rect>

                {/* Percentage text */}
                <text x="50" y="60" textAnchor="middle" fill="#1E40AF" fontSize="16" fontWeight="bold">
                    {fillPercentage}%
                </text>
            </svg>
        </div>
    );
};

const ReservoirGrid = () => {
    const reservoirs = Array(16).fill().map(() => Math.floor(Math.random() * 100));
    return (
        <div className="reservoir-grid">
            {reservoirs.map((fill, index) => (
                <CircularWaterJug key={index} fillPercentage={fill} />
            ))}
        </div>
    );
};

export default ReservoirGrid;