import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Admin() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        if (username && password) {
            fetch('BackendLogin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    Username: username,
                    Password: password
                })
            })
                .then(response => response.json())
                .then(data => {
                    if (data) {
                        navigate('/Backend/Home');
                    } else {
                        setError('登入失敗'); // 更新错误状态
                    }
                    
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        } else {
            alert('請輸入帳號密碼');
        }
        
    };

    return (
        <div>
            <h2>后台登录</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="username">用户名:</label>
                    <input
                        type="text"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="password">密码:</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                {error && <div style={{ color: 'red' }}>{error}</div>}
                <button type="submit">登录</button>
            </form>
        </div>
    );
}

export default Admin;
