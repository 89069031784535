import React from 'react';
import styles from './../Style/Intro.module.css';
import Footer from './Footer';

function WuXingOrigin() {
    return (
        <div className={styles.articleContainer}>
            <div className={styles.articleContentWrapper}>
                <header className={styles.articleHeader}>
                    <h1>中國五行的由來</h1>
                    <p>探討五行學說的起源及其神明傳說</p>
                </header>

                <section className={styles.articleContent}>
                    <p>
                        中國的五行學說起源於古代中國的哲學思想，是中國傳統文化中一個重要的宇宙觀和自然哲學體系。五行學說認為宇宙萬物是由五種基本元素構成的：木、火、土、金、水。五行之間存在相生相克的關係，這種關係用於解釋自然現象、人類社會、人體健康等多個方面。
                    </p>

                    <h3>五行的起源</h3>
                    <p>
                        五行學說形成於中國古代的春秋戰國時期，並在《尚書》、《左傳》、《禮記》等經典文獻中有所記載。最早系統闡述五行學說的經典包括《周易》、《黃帝內經》等，它們將五行作為解釋天地萬物生成變化的基本原理。
                    </p>

                    <h3>五行的具體內容</h3>
                    <ul>
                        <li><strong>木</strong>：代表生長、發育、柔和、向上、條達的特性，與春天、東方相對應。</li>
                        <li><strong>火</strong>：代表熱烈、光明、向上、發散的特性，與夏天、南方相對應。</li>
                        <li><strong>土</strong>：代表承載、包容、變化、穩定的特性，與長夏、中央相對應。</li>
                        <li><strong>金</strong>：代表肅殺、收斂、清靜、堅固的特性，與秋天、西方相對應。</li>
                        <li><strong>水</strong>：代表寒冷、滋潤、下沉、向下的特性，與冬天、北方相對應。</li>
                    </ul>

                    <h3>五行的相生相克</h3>
                    <ul>
                        <li><strong>相生</strong>：木生火、火生土、土生金、金生水、水生木。</li>
                        <li><strong>相克</strong>：木克土、土克水、水克火、火克金、金克木。</li>
                    </ul>

                    <h3>與五行相關的神明傳說</h3>
                    <p>
                        雖然五行本身是抽象的自然元素，但古人將五行與神明聯繫在一起，形成了許多相關的傳說和神話。每個五行都對應特定的神明，這些神明象徵著五行的力量並守護著天地。
                    </p>

                    <h3>青龍（木）</h3>
                    <p>
                        青龍是代表東方的木行神獸，象徵著生長與繁榮。
                    </p>

                    <h3>朱雀（火）</h3>
                    <p>
                        朱雀是代表南方的火行神獸，象徵著光明與熱情。
                    </p>

                    <h3>黃龍（土）</h3>
                    <p>
                        黃龍是代表中央的土行神獸，象徵著穩定與養育。
                    </p>

                    <h3>白虎（金）</h3>
                    <p>
                        白虎是代表西方的金行神獸，象徵著力量與肅殺。
                    </p>

                    <h3>玄武（水）</h3>
                    <p>
                        玄武是代表北方的水行神獸，象徵著長壽與安寧。
                    </p>
                </section>

                <Footer />
            </div>
        </div>
    );
}

export default WuXingOrigin;
