import React from 'react';
import styles from './../Style/Intro.module.css';
import Footer from './Footer';

function DizhiOrigin() {
    return (
        <div className={styles.articleContainer}>
            <div className={styles.articleContentWrapper}>
                <header className={styles.articleHeader}>
                    <h1>地支的由來</h1>
                    <p>探索地支系統的起源、發展及其文化意義</p>
                </header>

                <section className={styles.articleContent}>
                    <p>
                        地支系統是中國古代的一種時間記錄和占卜系統，與天干系統共同使用，形成了干支紀年法。地支與天文觀察和自然界變化有關，這套系統反映了古人對宇宙運行規律的理解。
                    </p>

                    <h3>地支的起源</h3>
                    <p>
                        地支的起源與古代中國的天文學和曆法密切相關。古人通過觀察月亮的運行週期，將這些觀察結果用於記錄時間和預測自然現象。地支系統由十二個符號組成，這些符號與動物、季節和方位相關聯。
                    </p>

                    <h3>地支與五行</h3>
                    <p>
                        地支的十二個符號（子、丑、寅、卯、辰、巳、午、未、申、酉、戌、亥）與五行思想相結合，每個地支與一個五行元素（木、火、土、金、水）相關聯。這樣的結合使得地支系統能夠表示時間、方向及自然界的變化。
                    </p>

                    <h3>地支與天干的結合</h3>
                    <p>
                        地支與天干的組合形成了六十年的干支紀年法。這種紀年方法用於記錄歷史事件、制定日曆和進行占卜。每個地支與天干的組合都代表了一個獨特的年份、月份或日子。
                    </p>

                    <h3>文化意義</h3>
                    <p>
                        地支系統在中國古代的文化和哲學中扮演了重要角色。它不僅用於記錄時間，還被用於預測天氣、制定農曆和進行占卜。地支系統反映了古代中國人對宇宙和自然界運行規律的理解。
                    </p>

                    <h3>具體的地支</h3>
                    <ul>
                        <li><strong>子 (Zǐ)</strong>: 水，陽</li>
                        <li><strong>丑 (Chǒu)</strong>: 土，陰</li>
                        <li><strong>寅 (Yín)</strong>: 木，陽</li>
                        <li><strong>卯 (Mǎo)</strong>: 木，陰</li>
                        <li><strong>辰 (Chén)</strong>: 土，陽</li>
                        <li><strong>巳 (Sì)</strong>: 火，陰</li>
                        <li><strong>午 (Wǔ)</strong>: 火，陽</li>
                        <li><strong>未 (Wèi)</strong>: 土，陰</li>
                        <li><strong>申 (Shēn)</strong>: 金，陽</li>
                        <li><strong>酉 (Yǒu)</strong>: 金，陰</li>
                        <li><strong>戌 (Xū)</strong>: 土，陽</li>
                        <li><strong>亥 (Hài)</strong>: 水，陰</li>
                    </ul>
                </section>

                <Footer />
            </div>
        </div>
    );
}

export default DizhiOrigin;
