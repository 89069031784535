// TagCloud.js
import React from 'react';
import './Style/TagCloud.css';

const TagCloud = ({ tags }) => {
    const getFontSize = (count) => {
        const baseSize = 12;
        const maxSize = 16; // 調整最大字體大小為16px
        const size = baseSize + (count / 10) * (maxSize - baseSize);
        return `${size}px`;
    };

    return (
        <ul className="tag-cloud">
            {tags.map((tag, index) => (
                <li key={index} className="tag">
                    <a href={tag.url} style={{ fontSize: getFontSize(tag.count) }}>
                        {tag.name}
                    </a>
                </li>
            ))}
        </ul>
    );
};

export default TagCloud;
