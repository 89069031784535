import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Style/lunarday.css';
function TimeCharacterResult() {
    const location = useLocation();
    const navigate = useNavigate();
    const { birthdate, birthtime } = location.state || {};
    const [data, setData] = useState({
        name: '',
    });

    useEffect(() => {
        if (birthdate && birthtime) {
            fetch('TimeCharacterBazi', {  // 替换为您的API地址
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    birthDate: birthdate,
                    birthTime: birthtime
                })
            })
                .then(response => response.json())
                .then(data => {
                    setData(data); // 或者根据您的逻辑进行其他处理
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }, [birthdate, birthtime]); // 只在 birthdate 或 birthtime 更改时运行

    const handleBack = () => {
        navigate(-1); // 返回上一页
    };

    return (
        <div>
            <button onClick={handleBack}>返回</button>
            <div className="App">
                <div class="fortune-description">
                    <header className="App-header">
                        <h1>{data.hourGanZhi}時生人</h1>
                        <p>{data.description}</p>
                    </header>
                </div>
            </div>
        </div>
    );
}

export default TimeCharacterResult;
