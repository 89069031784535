import React, { useEffect, useRef, useState } from 'react';

const CANVAS_WIDTH = 800;
const CANVAS_HEIGHT = 400;
const MARIO_WIDTH = 50;
const MARIO_HEIGHT = 50;
const GROUND_HEIGHT = 50;
const SPEED = 5;
const PLATFORM_WIDTH = 100;
const PLATFORM_HEIGHT = 20;
const MAX_JUMP_POWER = 15;
const JUMP_CHARGE_RATE = 0.5;
const FLAG_X = 750;
const FLAG_SIZE = 50;
const WELL_WIDTH = 60;
const WELL_HEIGHT = 100;
const WELL_X = 400;

function GameMario() {
    const canvasRef = useRef(null);
    const marioRef = useRef({
        x: 50,
        y: 200,
        dx: 0,
        dy: 0,
        jumping: false,
        jumpPower: 0
    });
    const marioImageRef = useRef(null);
    const flagImageRef = useRef(null);
    const jumpKeyPressedRef = useRef(false);
    const [gameWon, setGameWon] = useState(false);
    const fireworksRef = useRef([]);
    const fireworksTimerRef = useRef(null);
    const fireworksEndTimeRef = useRef(null);
    const wellRef = useRef({ x: WELL_X, y: CANVAS_HEIGHT - GROUND_HEIGHT - WELL_HEIGHT });

    const platformsRef = useRef([
        { x: 200, y: 250 },
        { x: 400, y: 200 },
        { x: 600, y: 150 },
    ]);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        let animationFrameId;

        const marioImage = new Image();
        marioImage.src = '/images/Mario.256.png';
        marioImageRef.current = marioImage;

        const flagImage = new Image();
        flagImage.src = '/images/flag_icon.png';
        flagImageRef.current = flagImage;

        function updateMario() {
            const mario = marioRef.current;
            const newX = mario.x + mario.dx;
            const newY = mario.y + mario.dy;

            // Check collision with platforms
            let collided = false;
            platformsRef.current.forEach(platform => {
                if (newX + MARIO_WIDTH > platform.x &&
                    newX < platform.x + PLATFORM_WIDTH) {
                    if (mario.y + MARIO_HEIGHT <= platform.y &&
                        newY + MARIO_HEIGHT > platform.y) {
                        mario.y = platform.y - MARIO_HEIGHT;
                        mario.dy = 0;
                        mario.jumping = false;
                        collided = true;
                    } else if (mario.y >= platform.y + PLATFORM_HEIGHT &&
                        newY < platform.y + PLATFORM_HEIGHT) {
                        mario.y = platform.y + PLATFORM_HEIGHT;
                        mario.dy = 0;
                        collided = true;
                    }
                }
            });

            // Check collision with well
            const well = wellRef.current;
            if (newX + MARIO_WIDTH > well.x && newX < well.x + WELL_WIDTH) {
                if (newY + MARIO_HEIGHT > well.y) {
                    if (mario.x + MARIO_WIDTH <= well.x) {
                        mario.x = well.x - MARIO_WIDTH;
                    } else if (mario.x >= well.x + WELL_WIDTH) {
                        mario.x = well.x + WELL_WIDTH;
                    } else if (mario.y + MARIO_HEIGHT <= well.y) {
                        mario.y = well.y - MARIO_HEIGHT;
                        mario.dy = 0;
                        mario.jumping = false;
                    }
                    collided = true;
                }
            }

            if (!collided) {
                mario.x = newX;
                mario.y = newY;
                mario.dy += 0.5; // gravity
            }

            // Check collision with ground
            if (mario.y + MARIO_HEIGHT > CANVAS_HEIGHT - GROUND_HEIGHT) {
                mario.y = CANVAS_HEIGHT - GROUND_HEIGHT - MARIO_HEIGHT;
                mario.dy = 0;
                mario.jumping = false;
            }

            mario.x = Math.max(0, Math.min(mario.x, CANVAS_WIDTH - MARIO_WIDTH));

            // Handle jump charging
            if (jumpKeyPressedRef.current && !mario.jumping) {
                mario.jumpPower = Math.min(mario.jumpPower + JUMP_CHARGE_RATE, MAX_JUMP_POWER);
            }

            // Check if the game is won
            if (mario.x + MARIO_WIDTH > FLAG_X && !gameWon) {
                setGameWon(true);
                fireworksEndTimeRef.current = Date.now() + 3000; // Set end time 3 seconds from now
                // Start fireworks, stop after 3 seconds
                fireworksTimerRef.current = setTimeout(() => {
                    fireworksRef.current = [];
                    setGameWon(false);
                }, 3000);
            }
        }

        class Particle {
            constructor(x, y, color) {
                this.x = x;
                this.y = y;
                this.color = color;
                this.radius = Math.random() * 2 + 1;
                this.speed = Math.random() * 2 + 1;
                this.angle = Math.random() * Math.PI * 2;
                this.alpha = 1;
                this.decay = Math.random() * 0.02 + 0.01;
            }

            update() {
                this.x += Math.cos(this.angle) * this.speed;
                this.y += Math.sin(this.angle) * this.speed;
                this.alpha -= this.decay;
            }

            draw(ctx) {
                ctx.save();
                ctx.globalAlpha = this.alpha;
                ctx.beginPath();
                ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
                ctx.fillStyle = this.color;
                ctx.fill();
                ctx.restore();
            }
        }

        function createFirework() {
            const particles = [];
            const particleCount = 100;
            const color = `hsl(${Math.random() * 360}, 100%, 50%)`;
            for (let i = 0; i < particleCount; i++) {
                particles.push(new Particle(FLAG_X + FLAG_SIZE / 2, CANVAS_HEIGHT - GROUND_HEIGHT - FLAG_SIZE / 2, color));
            }
            return particles;
        }

        function updateFireworks() {
            fireworksRef.current.forEach((firework, index) => {
                firework.forEach((particle, particleIndex) => {
                    particle.update();
                    if (particle.alpha <= 0) {
                        firework.splice(particleIndex, 1);
                    }
                });

                if (firework.length === 0) {
                    fireworksRef.current.splice(index, 1);
                }
            });

            // Stop adding new fireworks after the end time
            if (gameWon && Date.now() < fireworksEndTimeRef.current) {
                if (fireworksRef.current.length < 5) {
                    fireworksRef.current.push(createFirework());
                }
            }
        }

        function drawWell(ctx) {
            const well = wellRef.current;
            ctx.fillStyle = 'gray';
            ctx.fillRect(well.x, well.y, WELL_WIDTH, WELL_HEIGHT);
        }

        function gameLoop() {
            ctx.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);
            updateMario();
            updateFireworks();
            const mario = marioRef.current;

            // Draw Mario
            if (marioImageRef.current.complete) {
                ctx.drawImage(marioImageRef.current, mario.x, mario.y, MARIO_WIDTH, MARIO_HEIGHT);
            }

            // Draw ground
            ctx.fillStyle = 'green';
            ctx.fillRect(0, CANVAS_HEIGHT - GROUND_HEIGHT, CANVAS_WIDTH, GROUND_HEIGHT);

            // Draw well
            drawWell(ctx);

            // Draw platforms
            ctx.fillStyle = 'brown';
            platformsRef.current.forEach(platform => {
                ctx.fillRect(platform.x, platform.y, PLATFORM_WIDTH, PLATFORM_HEIGHT);
            });

            // Draw jump charge bar
            if (jumpKeyPressedRef.current && !mario.jumping) {
                ctx.fillStyle = 'yellow';
                ctx.fillRect(mario.x, mario.y - 10, MARIO_WIDTH * (mario.jumpPower / MAX_JUMP_POWER), 5);
            }

            // Draw flag icon
            if (flagImageRef.current.complete) {
                ctx.drawImage(flagImageRef.current, FLAG_X, CANVAS_HEIGHT - GROUND_HEIGHT - FLAG_SIZE, FLAG_SIZE, FLAG_SIZE);
            }

            // Draw fireworks
            fireworksRef.current.forEach(firework => {
                firework.forEach(particle => particle.draw(ctx));
            });

            animationFrameId = requestAnimationFrame(gameLoop);
        }

        marioImage.onload = gameLoop;
        flagImage.onload = gameLoop;

        function handleKeyDown(e) {
            const mario = marioRef.current;
            switch (e.code) {
                case 'ArrowLeft':
                    mario.dx = -SPEED;
                    break;
                case 'ArrowRight':
                    mario.dx = SPEED;
                    break;
                case 'Space':
                    if (!mario.jumping) {
                        jumpKeyPressedRef.current = true;
                    }
                    break;
            }
        }

        function handleKeyUp(e) {
            const mario = marioRef.current;
            switch (e.code) {
                case 'ArrowLeft':
                case 'ArrowRight':
                    mario.dx = 0;
                    break;
                case 'Space':
                    if (!mario.jumping) {
                        mario.dy = -mario.jumpPower;
                        mario.jumping = true;
                    }
                    jumpKeyPressedRef.current = false;
                    mario.jumpPower = 0;
                    break;
            }
        }

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
            cancelAnimationFrame(animationFrameId);
            if (fireworksTimerRef.current) {
                clearTimeout(fireworksTimerRef.current);
            }
        };
    }, [gameWon]);

    return (
        <canvas ref={canvasRef} width={CANVAS_WIDTH} height={CANVAS_HEIGHT} />
    );
}

export default GameMario;