import React from 'react';
import styles from './../Style/Intro.module.css';
import Footer from './Footer';

function TianganOrigin() {
    return (
        <div className={styles.articleContainer}>
            <div className={styles.articleContentWrapper}>
                <header className={styles.articleHeader}>
                    <h1>天干的由來</h1>
                    <p>探索天干系統的起源、發展及其文化意義</p>
                </header>

                <section className={styles.articleContent}>
                    <p>
                        天干（干支）系統源於古代中國的天文觀察和對自然界變化的理解。天干與地支結合，用於表示時間、日期、方向和占卜。這套系統的形成反映了古人對宇宙運行規律的探索。
                    </p>

                    <h3>天干的起源</h3>
                    <p>
                        天干的起源與古代中國的天文學密切相關。古人觀察到太陽和月亮的運行，並將這些觀察結果用於記錄時間和預測自然現象。天干的系統由十個符號組成，這些符號與五行（木、火、土、金、水）和陰陽學說密切相關。
                    </p>

                    <h3>天干與五行</h3>
                    <p>
                        天干的十個符號（甲、乙、丙、丁、戊、己、庚、辛、壬、癸）與五行思想相結合，每個天干與一個五行元素（木、火、土、金、水）相關聯。這樣的結合使得天干系統不僅能夠表示時間，還能反映自然界的變化。
                    </p>

                    <h3>天干與地支的結合</h3>
                    <p>
                        天干與地支的組合形成了六十年的干支紀年法。這種紀年方法用於記錄歷史事件、制定日曆和進行占卜。每個天干與地支的組合都代表了一個獨特的年份、月份或日子。
                    </p>

                    <h3>文化意義</h3>
                    <p>
                        天干系統在中國古代的文化和哲學中扮演了重要角色。它不僅用於記錄時間，還被用於預測天氣、制定農曆和進行占卜。天干系統反映了古代中國人對宇宙和自然界運行規律的理解。
                    </p>

                    <h3>具體的天干</h3>
                    <ul>
                        <li><strong>甲 (Jiǎ)</strong>: 木，陽</li>
                        <li><strong>乙 (Yǐ)</strong>: 木，陰</li>
                        <li><strong>丙 (Bǐng)</strong>: 火，陽</li>
                        <li><strong>丁 (Dīng)</strong>: 火，陰</li>
                        <li><strong>戊 (Wù)</strong>: 土，陽</li>
                        <li><strong>己 (Jǐ)</strong>: 土，陰</li>
                        <li><strong>庚 (Gēng)</strong>: 金，陽</li>
                        <li><strong>辛 (Xīn)</strong>: 金，陰</li>
                        <li><strong>壬 (Rén)</strong>: 水，陽</li>
                        <li><strong>癸 (Guǐ)</strong>: 水，陰</li>
                    </ul>
                </section>

                <Footer />
            </div>
        </div>
    );
}

export default TianganOrigin;
