// src/components/SolarTerms.js
import React, { useState, useEffect } from 'react';
import styles from './Style/SolarTerms.module.css'; // Import the CSS module

const SolarTermSearch = () => {
    const [year, setYear] = useState('');
    const [solarTerms, setSolarTerms] = useState([]);
    const [error, setError] = useState('');
    const [recentYears, setRecentYears] = useState([]);

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let i = 0; i < 10; i++) {
            years.push(currentYear - i); // Get the last 10 years
        }
        setRecentYears(years);
    }, []);

    const handleYearChange = (event) => {
        setYear(event.target.value);
    };

    const fetchSolarTerms = async () => {
        try {
            const response = await fetch('SolarTermCtrl', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    year: year,
                })
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.status}`);
            }

            const data = await response.json();
            setSolarTerms(data);
            setError(''); // Clear error
        } catch (err) {
            setError('Error fetching data. Please check the year and try again.');
            setSolarTerms([]); // Clear the data if there's an error
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (year) {
            fetchSolarTerms();
        } else {
            setError('Please select a year.');
        }
    };

    return (
        <div className={styles.container}>
            <h1>二十四節氣</h1>
            <form onSubmit={handleSubmit}>
                <label>
                    請選擇最近十年年份:
                    <select className={styles.dropdown} value={year} onChange={handleYearChange}>
                        <option value="">選擇年份</option>
                        {recentYears.map((y, index) => (
                            <option key={index} value={y}>{y}</option>
                        ))}
                    </select>
                </label>
                <button className={styles.submitButton} type="submit">查詢</button>
            </form>
            {error && <p className={styles.errorMessage}>{error}</p>}

            {solarTerms.length > 0 && (
                <table className={styles.solarTable}>
                    <thead>
                        <tr>
                            <th>節氣名稱</th>
                            <th>日期</th>
                            <th>時間</th>
                        </tr>
                    </thead>
                    <tbody>
                        {solarTerms.map((term, index) => (
                            <tr key={index}>
                                <td>{term.name}</td>
                                <td>{new Date(term.date).toLocaleDateString()}</td>
                                <td>{new Date(term.date).toLocaleTimeString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default SolarTermSearch;
