import React, { useState, useEffect, useRef } from 'react';
import ChatRoom from './ChatRoom';
import './Style/ChatRoom.css';

function ChatLogin() {
    const [username, setUsername] = useState('');
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [error, setError] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [assignedUsername, setAssignedUsername] = useState('');
    const inputRef = useRef(null);

    useEffect(() => {
        // 在組件掛載後將焦點設置在輸入框上
        inputRef.current?.focus();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (username.trim()) {
            setIsLoggingIn(true);
            setError('');
            try {
                const response = await fetch('ChathLogin', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        username: username,
                    })
                });
                if (response.ok) {
                    const data = await response.json();
                    setAssignedUsername(data.username);
                    setIsLoggedIn(true);
                } else {
                    const errorData = await response.json();
                    setError(errorData.message || 'Failed to login');
                }
            } catch (error) {
                setError(error.toString());
            }
            setIsLoggingIn(false);
        }
    };

    if (isLoggedIn) {
        return <ChatRoom user={assignedUsername} onLogout={() => setIsLoggedIn(false)} />;
    }

    return (
        <div className="dos-chat">
            <div className="main-chat">
                <div className="titlebar">DOS Chat Login</div>
                <div className="content">
                    <form onSubmit={handleSubmit}>
                        <div className="input-area">
                            <span className="prompt">Login&gt;</span>
                            <div className="input-wrapper">
                                <span className="blinking-cursor">█</span>
                                <input
                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    placeholder="Enter your username"
                                    required
                                    ref={inputRef}
                                />
                            </div>
                        </div>
                        <button type="submit" disabled={isLoggingIn} className="button">
                            {isLoggingIn ? 'LOGGING IN...' : 'LOGIN'}
                        </button>
                    </form>
                    {error && <p className="error-message">{error}</p>}
                </div>
            </div>
        </div>
    );
}

export default ChatLogin;