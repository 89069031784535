import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function GuiGuZiResult() {
    const location = useLocation();
    const navigate = useNavigate();
    const { birthdate, birthtime } = location.state || {};
    const [data, setData] = useState({
        ganZhi: '',
        name: '',
        poem: '',
        hourPillar: [],
        description1: '',
        description2: '',
        legacy: '',
        brother: '',
        background: '',
        marriage: '',
        children: '',
        summary: ''
    });

    useEffect(() => {
        if (birthdate && birthtime) {
            fetch('GuiGuZiBazi', {  // 替换为您的API地址
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    birthDate: birthdate,
                    birthTime: birthtime
                })
            })
                .then(response => response.json())
                .then(data => {
                    setData(data); // 或者根据您的逻辑进行其他处理
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }, [birthdate, birthtime]); // 只在 birthdate 或 birthtime 更改时运行

    const handleBack = () => {
        navigate(-1); // 返回上一页
    };

    return (
        <div>
            <button onClick={handleBack}>返回</button>
            <div>
                <h3>八字<u>年干時干</u>為 <span style={{ color: 'red' }}>{data.ganZhi}</span> 入此格</h3>
                <p>
                    <b>詩曰</b> : {data.poem}
                </p>

                {data.hourPillar.length > 0 && data.hourPillar.map((pillar, index) => (
                    <p key={index}>時柱 <span style={{ color: '#C00' }}>{pillar.hourGanZhi}</span> : {pillar.description}</p>
                ))}

                <h2>{data.name}總評</h2>
                <p>{data.description1}</p>
                <p>{data.description2}</p>

                <p><b>基業</b> : {data.legacy}</p>
                <p><b>兄弟</b> : {data.brother}</p>
                <p><b>行藏</b> : {data.background}</p>
                <p><b>婚姻</b> : {data.marriage}</p>
                <p><b>子息</b> : {data.children}</p>
                <p><b>收成</b> : {data.summary}</p>
            </div>
        </div>
    );
}

// 样式
const tdStyle = {
    border: '1px solid black',
    padding: '10px',
    textAlign: 'center',
    position: 'relative' // 为了绝对定位内部元素
};

const ganZhiStyle = {
    position: 'relative', // 相对定位
};

const fiveElementStyle = {
    position: 'absolute', // 绝对定位
    bottom: '5px',
    right: '5px',
    fontSize: 'smaller' // 更小的字体大小
};
const cangGanStyle = {
    position: 'absolute',
    bottom: '5px',
    left: '5px',
    fontSize: 'smaller'
};

export default GuiGuZiResult;