import React, { useState, useRef, useEffect } from 'react';
import './Style/AngryBirds.css';

const GameAngryBirds = () => {
    const slingshotPos = { x: 100, y: 500 };
    const initialBirdPos = { x: slingshotPos.x, y: slingshotPos.y - 110 };
    const [birdPosition, setBirdPosition] = useState(initialBirdPos);
    const [birdVelocity, setBirdVelocity] = useState({ x: 0, y: 0 });
    const [isLaunched, setIsLaunched] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [dragPosition, setDragPosition] = useState(initialBirdPos);
    const [score, setScore] = useState(0);

    const gameWidth = 800;
    const gameHeight = 600;
    const birdSize = 30;
    const targetSize = 50;
    const targetPosition = { x: gameWidth - 100, y: gameHeight - 100 };
    const gravity = 0.5;
    const maxDragDistance = 100; // 最大拉動距離

    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        const drawSlingshot = () => {
            // 畫木棍
            ctx.beginPath();
            ctx.moveTo(slingshotPos.x, slingshotPos.y);
            ctx.lineTo(slingshotPos.x, slingshotPos.y - 120);
            ctx.strokeStyle = 'brown';
            ctx.lineWidth = 10;
            ctx.stroke();

            // 畫繩子
            ctx.beginPath();
            ctx.moveTo(slingshotPos.x, slingshotPos.y - 100);
            ctx.lineTo(dragPosition.x, dragPosition.y);
            ctx.lineTo(slingshotPos.x, slingshotPos.y - 120);
            ctx.strokeStyle = 'black';
            ctx.lineWidth = 3;
            ctx.stroke();
        };

        ctx.clearRect(0, 0, gameWidth, gameHeight);
        drawSlingshot();
    }, [dragPosition]);

    useEffect(() => {
        if (isLaunched) {
            const interval = setInterval(() => {
                setBirdPosition(prev => {
                    const newX = prev.x + birdVelocity.x;
                    const newY = prev.y + birdVelocity.y;

                    let newVelocityX = birdVelocity.x;
                    let newVelocityY = birdVelocity.y + gravity;

                    if (newX <= 0 || newX + birdSize >= gameWidth) {
                        newVelocityX = 0;
                    }
                    if (newY + birdSize >= gameHeight) {
                        newVelocityY = 0;
                    }

                    setBirdVelocity({ x: newVelocityX, y: newVelocityY });

                    if (
                        newX < targetPosition.x + targetSize &&
                        newX + birdSize > targetPosition.x &&
                        newY < targetPosition.y + targetSize &&
                        newY + birdSize > targetPosition.y
                    ) {
                        setIsLaunched(false);
                        setScore(prevScore => prevScore + 1);
                        return prev;
                    }

                    if (Math.abs(newVelocityX) < 0.1 && Math.abs(newVelocityY) < 0.1) {
                        setIsLaunched(false);
                    }

                    return {
                        x: Math.max(0, Math.min(newX, gameWidth - birdSize)),
                        y: Math.max(0, Math.min(newY, gameHeight - birdSize))
                    };
                });
            }, 20);

            return () => clearInterval(interval);
        }
    }, [isLaunched, birdVelocity]);

    const handleMouseDown = (e) => {
        const rect = e.target.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        if (Math.abs(x - birdPosition.x) < birdSize && Math.abs(y - birdPosition.y) < birdSize) {
            setIsDragging(true);
        }
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
            const rect = e.target.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;

            // 計算與彈弓中心的距離
            const dx = x - slingshotPos.x;
            const dy = y - slingshotPos.y;
            const distance = Math.sqrt(dx * dx + dy * dy);

            // 限制拉動距離
            const limitedDistance = Math.min(distance, maxDragDistance);
            const angle = Math.atan2(dy, dx);

            const limitedX = slingshotPos.x + limitedDistance * Math.cos(angle);
            const limitedY = slingshotPos.y + limitedDistance * Math.sin(angle);

            setDragPosition({ x: limitedX, y: limitedY });
            setBirdPosition({ x: limitedX, y: limitedY });
        }
    };

    const handleMouseUp = () => {
        if (isDragging) {
            setIsDragging(false);
            setIsLaunched(true);
            const dx = slingshotPos.x - dragPosition.x;
            const dy = slingshotPos.y - dragPosition.y;
            const distance = Math.sqrt(dx * dx + dy * dy);
            const angle = Math.atan2(dy, dx);
            const power = distance * 0.2; // 增加彈力
            setBirdVelocity({
                x: power * Math.cos(angle),
                y: -power * Math.sin(angle) // 這裡修正方向
            });
            // 重置彈弓位置
            setDragPosition(initialBirdPos);
        }
    };

    const resetBird = () => {
        setBirdPosition(initialBirdPos);
        setDragPosition(initialBirdPos);
        setBirdVelocity({ x: 0, y: 0 });
        setIsLaunched(false);
    };

    return (
        <div className="game-container angry-birds-game" style={{ width: gameWidth, height: gameHeight }}>
            <canvas
                ref={canvasRef}
                width={gameWidth}
                height={gameHeight}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
            />
            <div
                className="bird"
                style={{
                    left: `${birdPosition.x - birdSize / 2}px`,
                    top: `${birdPosition.y - birdSize / 2}px`,
                    width: `${birdSize}px`,
                    height: `${birdSize}px`,
                    backgroundImage: `url(/images/bird-icon.png)`,
                    backgroundSize: 'cover'
                }}
            />
            <div
                className="target"
                style={{
                    left: `${targetPosition.x}px`,
                    top: `${targetPosition.y}px`,
                    width: `${targetSize}px`,
                    height: `${targetSize}px`,
                    backgroundColor: 'green'
                }}
            />
            <div className="controls">
                <button onClick={resetBird}>重置</button>
            </div>
            <div>分數: {score}</div>
        </div>
    );
};

export default GameAngryBirds;
