import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import '../Style/Backend.css';

export class BackendLayout extends Component {
    static displayName = BackendLayout.name;

    render() {
        return (
            <div className="wrapper">
                <div className="sidebar">
                    <ul>
                        <li><Link to="/Backend/BasicInfo">基本設定</Link></li>
                        <li><Link to="/backend/products">Products</Link></li>
                        <li><Link to="/backend/users">Users</Link></li>
                        {/* 添加更多菜单项 */}
                    </ul>
                </div>
                <div className="content">
                    <Container tag="main">
                        {this.props.children}
                    </Container>
                </div>
            </div>
        );
    }
}
