import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import { Home } from "./components/Home";
import Ming from "./components/Ming";
import MingResult from "./components/MingResult";
import GuiGuZiResult from "./components/GuiGuZiResult"
import LunarMansions28Result from "./components/LunarMansions28Result"
import LunarDayResult from "./components/LunarDayResult"
import ThreeLifetimesResult from "./components/ThreeLifetimesResult"
import TimeCharacterResult from "./components/TimeCharacterResult"
import Reservoir from "./components/Reservoir"
import SolarTermSearch from "./components/SolarTermSearch"

import ChatLogin from "./components/ChatLogin"
import ChatRoom from "./components/ChatRoom"


import SolarTerm from "./components/Articles/SolarTerm"
import MercuryRetrograde from "./components/Articles/MercuryRetrograde"
import WuXingOrigin from "./components/Articles/WuXingOrigin"
import TianganOrigin from "./components/Articles/TianganOrigin"
import DizhiOrigin from "./components/Articles/DizhiOrigin"



import GameMario from "./components/GameMario"
import GameAngryBirds from "./components/GameAngryBirds"

import Admin from "./components/Backend/Admin";
import BackendHome from "./components/Backend/Home";
import { BackendBasicInfo } from "./components/Backend/BasicInfo";

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/counter',
        element: <Counter />
    },
    {
        path: '/fetch-data',
        element: <FetchData />
    },
    {
        path: '/Ming/:type',
        element: <Ming />
    },
    {
        path: '/MingResult',
        element: <MingResult />
    },
    {
        path: '/GuiGuZiResult',
        element: <GuiGuZiResult />
    },
    {
        path: '/LunarMansions28Result',
        element: <LunarMansions28Result />
    },
    {
        path: '/LunarDayResult',
        element: <LunarDayResult />
    },
    {
        path: '/ThreeLifetimesResult',
        element: <ThreeLifetimesResult />
    },
    {
        path: '/TimeCharacterResult',
        element: <TimeCharacterResult />
    },
    {
        path: '/SolarTermSearch',
        element: <SolarTermSearch />
    },
    {
        path: '/ChatLogin',
        element: <ChatLogin />
    },
    {
        path: '/ChatRoom',
        element: <ChatRoom />
    },
    {
        path: '/GameMario',
        element: <GameMario />
    },
    {
        path: '/Reservoir',
        element: <Reservoir />
    },
    {
        path: '/GameAngryBirds',
        element: <GameAngryBirds />
    },
    ,
    {
        path: '/SolarTerm',
        element: <SolarTerm />
    },
    {
        path: '/MercuryRetrograde',
        element: <MercuryRetrograde />
    },
    {
        path: '/WuXingOrigin',
        element: <WuXingOrigin />
    },
    {
        path: '/TianganOrigin',
        element: <TianganOrigin />
    },
    {
        path: '/DizhiOrigin',
        element: <DizhiOrigin />
    },
    {
        path: '/Admin',
        element: <Admin />
    },
    {
        path: '/Backend/Home',
        element: <BackendHome />
    }
    ,
    {
        path: '/Backend/BasicInfo',
        element: <BackendBasicInfo />
    }
];

export default AppRoutes;
